<template>
  <v-dialog id="dialog" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="primary--text">
        <v-icon class="mr-1">add_box</v-icon>
        Generate Quick Registration Token
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Create New Registration Token</v-card-title>
      <v-card-text>
        <form v-on:submit.prevent="createDeviceRegistrationQuickToken()">
          <v-text-field required label="Name" v-model="deviceRegistrationToken.name" />
          <v-text-field
            label="Description" 
            v-model="deviceRegistrationToken.image" 
            />
          <v-text-field 
            required 
            number
            min="0"
            label="Max registrations" 
            hint="Set it to 0 for unlimited registrations"
            persistent-hint
            v-model="deviceRegistrationToken.maxRegistrations" 
            />
          
          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <div v-show="error">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="dialog = false" class="primary--text">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" :loading="loading" v-on:click.native.stop="createDeviceRegistrationToken()" class="primary">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>

  export default {
    data () {
      return {
        dialog: false,

        deviceRegistrationToken: {
          name: '',
          description: '',
          maxRegistrations: 0,
          projectId: ''
        }
      }
    },

    computed: {
      loading() {
        return this.$store.state.device.loading
      },
      error() {
        return this.$store.state.device.error
      },
      projectId() {
        return this.$route.params.project
      }
    },

    methods: {
      createDeviceRegistrationQuickToken () {
        this.deviceRegistrationToken.projectId = this.projectId

        let payload = {
          projectId: this.projectId,
          name: this.deviceRegistrationToken.name,
          description: this.deviceRegistrationToken.description
        }

        if (this.deviceRegistrationToken.maxRegistrations > 0) {
          payload.maxRegistrations = parseInt(this.deviceRegistrationToken.maxRegistrations)
        }

        this.$store.dispatch('CreateDeviceRegistrationToken', payload).then(() => {
          let q = {
            projectId: this.projectId
          } 
          this.$store.dispatch('ListDeviceRegistrationTokens', q)
          this.resetForm()
        })
      },

      resetForm: function () {
        // closing dialog
        this.dialog = false

        // resetting form
        this.deviceRegistrationToken.name = ''
        this.deviceRegistrationToken.description = ''
        this.deviceRegistrationToken.maxRegistrations = null
      }
    }
  }
</script>
